<template>
    <base-page>
        <titlu-pagina Titlu="Pontaj institutie" :PrintVisible="false" @on_print_clicked="export_pdf" />
        <el-card style='margin:5px 0px 5px 0px'>
            <div slot="header" class="clearfix">
                <strong> Filtre </strong>
            </div>
            <div class="filtre">
                <el-form @submit.prevent='refresh_info()'>
                    <el-row :gutter="20">
                        <el-col :span='12'>
                            <el-form-item label='Departament' prop="IdDepartament">
                                <SelectDepartament v-model="Filters.Departament" @selectie="handleSelectDepartament" :IdProiect.sync="Filters.IdDepartament" />                        
                            </el-form-item>
                        </el-col>  

                        <el-col :span='6'>
                            <el-form-item label='Angajat' prop="IdAngajat" >                                   
                                <SelectAngajat v-model="Filters.NumeAngajat" :IdAngajat.sync="Filters.IdAngajat" arataTipul ExcludePresedinteVicepresedinte/>                       
                            </el-form-item>
                        </el-col>  

                        <el-col :span='4'>
                            <el-form-item label='Luna' prop="Data">
                                <el-date-picker class='full-width' v-model="Filters.Data" type="month" value-format='yyyy-MM' placeholder="Alege luna"></el-date-picker>
                            </el-form-item>
                        </el-col> 

                        <el-col :md='24' >
                            <el-button @click="reset()" > Reset  </el-button>
                            <el-button type='primary' native-type='submit' @click='refresh_info()'> Aplica </el-button>
                        </el-col>   

                    </el-row>
                </el-form>
            </div>
        </el-card>

        <el-table :data="Results" class="tabel-intrari" v-loading="loader_tabel">     
            <el-table-column type="expand" width="50px;">
                <template slot-scope="scope" >
                    <el-table :data="scope.row.Angajati" :row-class-name="tableRowClassName">
                        <el-table-column type="index" width="20px;"></el-table-column>
                        <el-table-column label="Angajat"  width="200px;">
                             <template slot-scope="scope">
                                 <!-- <el-tooltip placement="top-start" > -->
                                     <!-- <div slot="content">
                                        <el-button size="mini" type="info" @click="show_istoric(scope.row)">Istoric validari</el-button>
                                    </div> -->
                                    <div>{{scope.row.NumeAngajat}}</div>
                                 <!-- </el-tooltip> -->
                             </template>
                        </el-table-column>
                        <!-- <el-table-column label="Validat" prop="ultima_validare"></el-table-column> -->
                        <el-table-column width="120px;">
                            <template slot-scope="scope">
                                <div style="float:right" v-if="$is_user_type('Responsabil proiect')" >
                                    <div v-if="scope.row.ultima_validare == '-' && maiVechiDecatLunaCurenta(scope.row)">
                                        <el-tooltip content="Valideaza (Responsabil Proiect)" placement="top-start">
                                            <el-button size="mini" type="success" icon="el-icon-check" @click.prevent="valideaza(scope.row, 'Responsabil proiect')" round ></el-button>
                                        </el-tooltip>            
                                    </div>                                    
                                    <div v-if="scope.row.ultima_validare == 'Responsabil proiect'">
                                        <el-tooltip content="Invalideaza (Responsabil Proiect)" placement="top-start">
                                            <el-button size="mini" type="danger" icon="el-icon-error" @click.prevent="invalideaza(scope.row, 'Responsabil proiect')" round ></el-button>
                                        </el-tooltip>            
                                    </div> 
                                </div>
                                <div style="float:right" v-if="$is_user_type('Manager de proiect')" >    
                                    <div v-if="scope.row.ultima_validare == 'Responsabil proiect' && maiVechiDecatLunaCurenta(scope.row)">                       
                                        <el-tooltip content="Valideaza (Manager de proiect)" placement="top-start">
                                            <el-button size="mini" type="warning" icon="el-icon-check" @click.prevent="valideaza(scope.row, 'Manager proiect')" round ></el-button>
                                        </el-tooltip>            
                                    </div>                                       
                                    <div v-if="scope.row.ultima_validare == 'Manager proiect'">
                                        <el-tooltip content="Invalideaza (Manager de proiect)" placement="top-start">
                                            <el-button size="mini" type="danger" icon="el-icon-error" @click.prevent="invalideaza(scope.row, 'Manager proiect')" round ></el-button>
                                        </el-tooltip>            
                                    </div>                     
                                </div>                                   
                                <div style="float:right" v-if="$is_user_type('Economic')" >    
                                    <div v-if="scope.row.ultima_validare == 'Manager proiect' && maiVechiDecatLunaCurenta(scope.row)">                       
                                        <el-tooltip content="Valideaza (Economic)" placement="top-start">
                                            <el-button size="mini" type="warning" icon="el-icon-check" @click.prevent="valideaza(scope.row, 'Economic')" round ></el-button>
                                        </el-tooltip>            
                                    </div>                             
                                    <div v-if="scope.row.ultima_validare == 'Economic'">
                                        <div>
                                            <el-tooltip content="Invalideaza (Economic)" placement="top-start">
                                                <el-button size="mini" type="danger" icon="el-icon-error" @click.prevent="invalideaza(scope.row, 'Economic')" round ></el-button>
                                            </el-tooltip>    
                                                                         
                                            <el-tooltip content="Genereaza calcul salarii" placement="top-start">
                                                <el-button size="mini" type="success" icon="el-icon-success" @click.prevent="genereaza_salarii(scope.row,)" round ></el-button>
                                            </el-tooltip>    
                                        </div>        
                                    </div>                     
                                </div>                     
                            </template>
                        </el-table-column>
                        <el-table-column v-for="item in scope.row.NumarZile" :key='item' :label="''+item" align="center" width="65px" style="background-color:red">
                            <template slot-scope="scope">    
                                <div v-if="typeof scope.row.Ore[item] == 'string' || scope.row.Ore[item] == 0 || (typeof scope.row.Ore[item] == 'object' && scope.row.Ore[item].Prescurtare == 'S')">
                                    <el-tag 
                                        :type="setEntryColorType(scope.row.Ore[item])" 
                                        :effect="setEntryEffectType(scope.row.Ore[item])">
                                        {{typeof scope.row.Ore[item] == 'object' ? scope.row.Ore[item].Prescurtare : scope.row.Ore[item]}}
                                    </el-tag> 
                                </div>
                                <div v-else>
                                    <el-tag class="hover-as-button" 
                                        :type="setEntryColorType(scope.row.Ore[item])" 
                                        :effect="setEntryEffectType(scope.row.Ore[item])">{{typeof scope.row.Ore[item] == 'object' ? scope.row.Ore[item].Prescurtare : scope.row.Ore[item]}}
                                    </el-tag> 
                                </div>                                                                                                                       
                            </template>
                        </el-table-column>
                        <el-table-column label="TOTAL" >
                            <template slot-scope="scope">
                                <el-tag effect="dark">{{scope.row.Ore.Total}}  </el-tag>     
                                <el-tooltip content="Vezi istoric" >
                                                                    
                                </el-tooltip>                         
                            </template>
                        </el-table-column>
                        <el-table-column label="CONCEDIU" width="100px" align="center">
                            <template slot-scope="scope">
                                <el-tag effect="dark">{{scope.row.Ore.TotalConcediu}}  </el-tag>     
                                <el-tooltip content="Vezi istoric" >
                                                                    
                                </el-tooltip>                         
                            </template>
                        </el-table-column>                        
                    </el-table>
                </template>
            </el-table-column>       
            <el-table-column prop="NumeDepartament" ></el-table-column>
            <el-table-column> 
                <template slot-scope="scope">
                    <el-button icon="el-icon-document" plain @click="exportExcel(scope.row.Id)"> Excel </el-button>
                </template>
            </el-table-column>

        </el-table>
        <el-pagination @size-change="refresh_info" @current-change="refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />

    </base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import TitluPagina from '@/widgets/TitluPagina';
import ModificareOrePontateDialog from '../pontaj/Modificare_ore_pontate_dialog';
import IstoricValidari from '@/components/IstoricValidari';
import moment from 'moment';

export default {
    name: "pontaj-institutie",
    extends: BasePage,
    components: {
        'base-page': BasePage,
        'titlu-pagina': TitluPagina,
        ModificareOrePontateDialog,
        IstoricValidari        
    },
    data () {
        return {
            Results: [],
            rand: [],
            TitluTabel: '',
            base_url: '',
            Info: { proiecte: [] },                
            Filters: { NumeAngajat:'', IdAngajat:'-1',  Data:moment().format("yyyy-MM"), IdDepartament: '' },               
            OrderBy: { },
            PaginationInfo: { Page: 1, PerPage: 50, RowCount: 0, PageSizes: [10, 25, 50, 100, 200] },
            loader_tabel: true
        }
    },
    computed:{      
    },
    methods: {
      
        maiVechiDecatLunaCurenta(row) {

            let date = new Date();
            let luna_curenta = new Date(date.getFullYear(), date.getMonth(), 1);
            let luna_pontaj = new Date(row.An, row.Luna - 1, 1);

            if(luna_pontaj < luna_curenta){
                return true;
            }
            return false;
        },        
        tableRowClassName({row}) {

            if (row.Culoare == 'rosu') {
                return 'danger-row';
            } else if (row.Culoare == 'albastru') {
                return 'info-row';} 
            else if (row.Culoare == 'verde') {
                return 'success-row';
            }
            return '';
        },
        async genereaza_salarii(rand){

            var confirm = await this.$confirm(`Generati salarii pentru  ` + rand.NumeAngajat + '?', 'Warning');
            if( confirm )
            {
                alert('OK');
            }
        },         
        async valideaza(rand, tip){

            var confirm = await this.$confirm(`Validati pontajul pentru ` + rand.NumeAngajat + '?', 'Warning');
            if( confirm )
            {
                await this.post("pontaj/valideaza", { rand: rand, TipValidare: tip } );
                this.refresh_info();

            }
        },        
        async invalideaza(rand, tip){
             this.$prompt(`Introduceti motivul anularii pontajului pentru ` + rand.NumeAngajat + '.', 'Stergere pontaj', {               
                type: 'warning',
                inputPattern: /./,
                inputErrorMessage: 'introduceti motivul anularii'
                }).then(({value}) => {
                    this.post("pontaj/invalideaza", { rand: rand, TipValidare: tip, ObsAnulare: value } );
                    this.refresh_info(); 
                    //this.$message({type: 'info', message: 'Pontaj invalidat'});                                                                       
                }).catch(() => {
                    this.$message({type: 'info', message: 'Anulat'});                                                                 
                });
        },
        show_istoric(rand){
                this.$refs.istoricValidariModal.show_me(rand);
        },
        setEntryColorType(val){
            if(typeof val == "string" || val == 0 || (typeof val == "object" && val.Prescurtare == "S"))
                return "info"            
            return "";
        },
        setEntryEffectType(val){
            if(typeof val == "string" || val == 0)
                return "light"      
            if(typeof val == "object" && val.Prescurtare == "S")
                return "light";  
            return "dark";
        },     
        async refresh_info(){
            this.loader_tabel   = true
            var response        = await this.post("pontaj_institutie/index", { Filters: this.Filters, OrderBy: this.OrderBy, PaginationInfo: this.PaginationInfo } );
            this.Results        = response.Results;

            this.Results.forEach(result => {
                let NumarZile = Object.keys(result.Angajati[0].Ore).length
                result.NumarZile = NumarZile - 2
            })

            this.TitluTabel     = response.TitluTabel;
            this.PaginationInfo = response.PaginationInfo;
            this.loader_tabel   = false
            this.select_menu_item('pontaj_institutie');
        },
        async export_pdf() {
            var token = this.Filters
            token = JSON.stringify(token)
            token = encodeURIComponent(token)
            var params = '?tokens=' + token
            // window.open("http://ananp.brk-dev.ro/api/index.php/pontaj_institutie/export_pdf/" + params, '_blank');  
            window.open(settings.BASE_URL + "pontaj_institutie/export_pdf/" + params, '_blank');  
        },
        reset(){
            this.Filters = {NumeAngajat:'', IdAngajat:'-1',  Data:moment().format("yyyy-MM"), IdDepartament: '' };
            this.refresh_info();
        },
        handleSelectDepartament(item) {
            this.Filters.IdDepartament = item.Id
        },
        exportExcel(idGrupaOrganizare){
            console.log('export');
            window.open(`${this.base_url}pontaj_institutie/export_excel/${idGrupaOrganizare}/${this.Filters.Data}`);
            // window.open("/#/pontaj_institutie_excel/" + item.Id, '_blank');
        }
    },
    mounted(){
        this.base_url = settings.BASE_URL;
        this.refresh_info();
    }
};
</script>

<style lang="less" >
    .floatRight {
        float: right;
    }
    .top50{
        margin-top: 20px;
    }

    .filtre{
        .el-input-number
        {
            width: 100% !important;
        }
    }

    .tabel-intrari{
        .el-table__expanded-cell{
            padding-left: 0px;
        }
    }

    .hover-as-button:hover{
       cursor: pointer;
    }

     .el-table .danger-row {
        background: #b97d7d;
    }

    .el-table .info-row {
        background: #70829c;
    }
    
    .el-table .success-row {
        background: #8db478;
    }

</style>
